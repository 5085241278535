export interface FormInterface {
  name: string
  vatnr: string
  phone: string
  email: string
  bankaccountiban: string
  bankaccountbic: string
  street: string
  housenumber: string
  postalcode: string
  city: string
  country: string
}

export const resetDefaultForm = () => ({
  name: '',
  vatnr: '',
  phone: '',
  email: '',
  bankaccountiban: '',
  bankaccountbic: '',
  street: '',
  housenumber: '',
  postalcode: '',
  city: '',
  country: ''
})
