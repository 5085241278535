





































































import UserApi from '@/services/UserApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { uniq } from 'lodash'
import PaginationInterface from '../../typings/PaginationInterface'

@Component({
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo', contractDTA: 'chargepoints/contractDTA' })
  }
})
export default class ChargePointList extends Vue {
  @Prop([String, Number]) readonly id!: string|number

  loading = false
  isAllChargepoint = false
  search= ''
  page = 1
  pageCount = 0

  pagination: PaginationInterface = {
    pagesize: 10,
    pagenumber: 1,
    type: 0
  }

  headers= [
    { text: this.t('$vuetify.chargepoint.table.chargepoint'), value: 'chargeboxidentifier', sortable: false },
    { text: this.t('$vuetify.chargepoint.table.description'), value: 'description', sortable: false },
    { text: this.t('$vuetify.chargepoint.table.location'), value: 'relationcontact', sortable: false },
    // { text: this.t('$vuetify.chargepoint.table.publicCharging'), value: 'description', sortable: false },
    // { text: this.t('$vuetify.chargepoint.table.published'), value: 'subscriptiontype', sortable: false },
    // { text: this.t('$vuetify.chargepoint.table.reimbursement'), value: 'state', sortable: false },
    { text: this.t('$vuetify.chargepoint.table.connectors'), value: 'state', sortable: false }
  ]

  data: Array<any> = []

  mounted () {
    this.fetchData()
  }

  getColor (connector: any) {
    if (connector.state === 'OCCUPIED') {
      return connector.sessionstate
    }
    return connector.state
  }

  async fetchData (isAllChargepoint = false) {
    try {
      this.loading = true
      const data = await new UserApi().getGenericInfo(this.id, 'ChargePoint', !isAllChargepoint) as any
      if (!this._.isEmpty(data)) {
        this.data = data.result
      }
    } catch (error) {
      console.log(error)
    }
    this.loading = false
  }

  async getDetails (data: any) {
    this.$router.push({ name: 'navchargepointsdetails', params: { id: data.id } })
  }

  @Watch('id', { immediate: false })
  idOnChanged (val: any, oldVal: any) {
    this.$nextTick(() => this.fetchData())
  }

  @Watch('isAllChargepoint', { immediate: false })
  isAllChargepointOnChanged () {
    this.$nextTick(() => this.fetchData(this.isAllChargepoint))
  }

  address (placements: Array<any>) {
    if (placements.length > 0) {
      return placements[(placements.length - 1)].address
    }
    return ''
  }

  published (placements: Array<any>) {
    if (placements.length > 0) {
      let tmp: Array<boolean> = []
      for (let i = 0; i < placements.length; i++) {
        const el = placements[i]
        if (el.published === 'NO') {
          tmp.push(false)
        } else {
          tmp.push(true)
        }
      }
      tmp = uniq(tmp)
      if (tmp.length === 1) {
        return tmp[0]
      } else {
        return true
      }
    }
    return false
  }

  forPublicCharging (placements: Array<any>) {
    if (placements.length > 0) {
      let tmp: Array<boolean> = []
      for (let i = 0; i < placements.length; i++) {
        const el = placements[i]
        if (el.evtype === 'PRIVATE') {
          tmp.push(false)
        } else {
          tmp.push(true)
        }
      }
      tmp = uniq(tmp)
      if (tmp.length === 1) {
        return tmp[0]
      } else {
        return true
      }
    }
    return false
  }

  reimbursement (placements: Array<any>) {
    if (placements.length > 0) {
      let tmp: Array<string> = []
      for (let i = 0; i < placements.length; i++) {
        const el = placements[i]
        tmp.push(el.reimbursement)
      }
      tmp = uniq(tmp)
      return tmp.join(' - ')
    }
    return ''
  }
}
