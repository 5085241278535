














































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Ref } from 'vue-property-decorator'
import { TreeConfigModel } from '../../components/relationTree/types/TreeConfigNode'
import DetailsCardOrganisation from '../../components/relation/DetailsCardOrganisation.vue'
import DetailsRC from '../../components/relation/DetailsRC.vue'
import DetailsCardDetails from '../../components/relation/DetailsCardDetails.vue'
import RelationContactDetailCard from '../../components/relationcontact/RelationContactDetailCard.vue'
import RelationApi, { RelationInterface } from '../../services/RelationApi'
import RelationContactApi from '../../services/RelationContactApi'
import NewModal from './NewUserOrganisation.vue'
import NewOrganisation from '../../views/_enduser/NewOrganisation.vue'
import { mapGetters } from 'vuex'
import ChargePointList from '../../components/chargepoint/ChargePointList.vue'
import {
  RelationContactInterface,
  RelationTreeInterface
} from '@/typings/RequestInterface'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'

enum Types {
  Relation = 'Relation',
  RelationContact = 'RelationContact'
}

interface ClickedItemInterface
  extends RelationInterface,
    RelationContactInterface {
  type: Types
}

@Component({
  components: {
    DetailsCardOrganisation,
    DetailsCardDetails,
    RelationContactDetailCard,
    NewModal,
    ChargePointList,
    DetailsRC,
    NewOrganisation
  },
  computed: {
    ...mapGetters({
      userDTA: 'user/userInfo',
      contractDTA: 'chargepoints/contractDTA'
    })
  }
})
export default class MyOrganisation extends Vue {
  @Ref() readonly tree!: HTMLElement
  isRelationView = false
  isLoadingTree = false
  items: Array<RelationTreeInterface> | RelationTreeInterface = []
  isEdit = false
  loadingDetails = false
  active: Array<RelationTreeInterface> = []
  selected: RelationInterface | null = null
  selectedType = ''
  newOrganisationModal = false
  isAsc = true
  config: TreeConfigModel = {
    Relation: {
      KeySuffix: 1,
      Icon: 'mdi-domain',
      DisplayName: 'Relation',
      SystemName: 'Relation',
      AllowedParent: true,
      DisplayProperty: 'Description'
    },
    RelationContact: {
      KeySuffix: 2,
      Icon: 'mdi-account',
      DisplayName: 'RelationContact',
      SystemName: Types.RelationContact,
      AllowedParent: false,
      DisplayProperty: 'Description'
    },
    ChargePoint: {
      KeySuffix: 3,
      Icon: 'mdi-ev-station',
      DisplayName: 'ChargePoint',
      SystemName: 'ChargePoint',
      AllowedParent: false,
      DisplayProperty: 'Description'
    },
    Token: {
      KeySuffix: 4,
      Icon: 'mdi-credit-card',
      DisplayName: 'Token',
      SystemName: 'Token',
      AllowedParent: false,
      DisplayProperty: 'Description'
    },
    ChargeLocation: {
      KeySuffix: 5,
      Icon: 'mdi-map-marker',
      DisplayName: 'ChargeLocation',
      SystemName: 'ChargeLocation',
      AllowedParent: true,
      DisplayProperty: 'Description'
    },
    ChargePointPlacement: {
      KeySuffix: 3,
      Icon: 'mdi-map-marker',
      DisplayName: 'ChargePointPlacement',
      SystemName: 'ChargePointPlacement',
      AllowedParent: false,
      DisplayProperty: 'ChargeLocationLabel'
    }
  }

  selectedRelationContact: RelationContactInterface = {}

  async loadChildren(val: RelationTreeInterface) {
    const dt = (await new RelationApi(0).relationTree({
      parentId: Number(val.id)
    })) as DefaultResponseInterface<RelationTreeInterface>
    val.children = this.formatTree(dt.data)
  }

  async clicked(val: ClickedItemInterface | RelationTreeInterface) {
    this.isRelationView = true
    this.loadingDetails = true
    if (val.type === Types.Relation) {
      const dt = (await new RelationApi(0).getRelationById(
        String(val.id)
      )) as DefaultResponseInterface<RelationInterface>
      this.selectedType = Types.Relation
      this.selected = dt.data
    } else if (val.type === Types.RelationContact) {
      const dt = (await new RelationContactApi(
        0
      ).findContactsByRelationContactId(
        Number(val.id)
      )) as DefaultResponseInterface<RelationContactInterface>
      this.selectedType = Types.RelationContact
      this.selected = dt.data
    }
    this.loadingDetails = false
  }

  async reloadRelation(val: RelationInterface) {
    const dt = (await new RelationApi(0).getRelationById(
      String(val.Id)
    )) as DefaultResponseInterface<RelationInterface>
    this.selectedType = Types.Relation
    this.selected = dt.data
    this.reloadTree()
  }

  async reloadDetailRelation() {
    if (this.selectedRelationContact.Id) {
      try {
        const dt = (await new RelationContactApi(
          0
        ).findContactsByRelationContactId(
          this.selectedRelationContact.Id
        )) as DefaultResponseInterface<RelationContactInterface>
        this.isRelationView = false
        this.selectedRelationContact = dt.data
      } catch (error) {
        console.log(error)
      }
    }
  }

  switchView(elm: ClickedItemInterface) {
    this.isRelationView = true
    this.selectedRelationContact = {}
    this.$nextTick(() => {
      this.clicked(elm)
    })
  }

  async clickedRelationContact(elm: RelationContactInterface) {
    try {
      const dt = (await new RelationContactApi(
        0
      ).findContactsByRelationContactId(
        Number(elm.id)
      )) as DefaultResponseInterface<RelationContactInterface>
      this.isRelationView = false
      this.selectedRelationContact = dt.data
    } catch (error) {
      console.log(error)
    }
  }

  formatTree(object: RelationTreeInterface, isRoot = false) {
    if (isRoot) {
      const obj = object.parent
      if (obj && obj.children) {
        delete object.parent
        obj.children = []
        for (const [key, value] of Object.entries(object)) {
          if (key) {
            obj.children = obj.children.concat(value)
          }
        }
        return [obj]
      }
    } else {
      let array: Array<RelationTreeInterface> = []
      delete object.parent
      for (const [key, value] of Object.entries(object)) {
        if (key) {
          array = array.concat(value)
        }
      }
      return array
    }
  }

  async getTree() {
    this.isLoadingTree = true
    const dt = (await new RelationApi(0).relationTree(
      {},
      this.isAsc
    )) as DefaultResponseInterface<RelationTreeInterface>
    this.isLoadingTree = false
    const parent = dt.data.parent as RelationTreeInterface
    const formattedTree = this.formatTree(dt.data, true)
    if (formattedTree) {
      this.items = formattedTree
    }
    this.$nextTick(() => {
      this.active = [parent]
    })
  }

  async reloadTree() {
    this.isLoadingTree = true
    const dt = (await new RelationApi(0).relationTree(
      {},
      this.isAsc
    )) as DefaultResponseInterface<RelationTreeInterface>
    this.isLoadingTree = false
    const formattedTree = this.formatTree(dt.data, true)
    if (formattedTree) {
      this.items = formattedTree
    }
  }

  async getTreeBis() {
    this.isLoadingTree = true
    const dt = (await new RelationApi(0).relationTree(
      {},
      this.isAsc
    )) as DefaultResponseInterface<RelationTreeInterface>
    this.isLoadingTree = false
    const parent = dt.data.parent as RelationTreeInterface
    const formattedTree = this.formatTree(dt.data, true)
    if (formattedTree) {
      this.items = formattedTree
    }
    this.$nextTick(() => {
      this.active = [parent]
      this.clicked(parent)
    })
  }

  @Watch('active', { deep: true })
  activeOnChanged(
    val: RelationTreeInterface[],
    oldVal: RelationTreeInterface[]
  ) {
    if (oldVal.length > 0) {
      if (val[0] && val[0].id !== oldVal[0].id) {
        this.clicked(val[0])
      }
    } else {
      if (val.length > 0) {
        this.clicked(val[0])
      }
    }
  }

  mounted() {
    this.getTree()
  }
}
