















































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, PropSync, Ref, Watch } from 'vue-property-decorator'
import RelationApi, { RelationInterface } from '@/services/RelationApi'
import { clone } from '../relationTree/functions'
import {
  hasIbanSpecialChars,
  isAddress,
  isHouseNumber,
  isMaxLengthCheck
} from '../../functions/formRules'
import BcCountrySelect from '@/components/_base/BcCountrySelect'
import DisabledEmailField from '@/components/common/DisabledEmailField.vue'
import { FormInterface, resetDefaultForm } from '@/typings/EditOrganisation'
import { OpenIbanResponse } from '@/typings/OpenIban'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'
import { isRequired } from '@bluecorner/shared'

@Component({
  components: {
    DisabledEmailField,
    BcCountrySelect
  }
})
export default class EditOrganisation extends Vue {
  @PropSync('show', { type: Boolean }) showModal!: boolean
  @Ref() readonly RelationForm!: HTMLFormElement
  @Prop(Object) readonly relation?: RelationInterface

  valid = true
  loading = false
  editVals: FormInterface = resetDefaultForm()

  initRel: RelationInterface | null = null
  ibanErrorMsg = ''
  rulesVAT = []

  rulesAddress = [isAddress('Invalid')]

  rulesHouseNumber = [isHouseNumber('Invalid house number')]

  rulesPhone = [isRequired()]

  ruleReference = [
    isMaxLengthCheck('Maximum no. of characters allowed is 50', 50)
  ]

  rulesName = [
    isMaxLengthCheck('Maximum no. of characters allowed is 100', 100),
    isRequired()
  ]

  @Watch('relation', { immediate: true })
  relationOnChanged(val: RelationInterface) {
    this.initRelation(val)
  }

  cancel() {
    this.showModal = false
    this.ibanErrorMsg = ''
    this.RelationForm.resetValidation()
    this.editVals = resetDefaultForm()
    if (this.initRel) {
      this.initRelation(this.initRel)
    }
  }

  initRelation(val: RelationInterface) {
    const relations = clone(val)
    const keys = Object.keys(relations)
    keys.forEach((e) => {
      switch (e) {
        case 'AccountancyId':
        case 'CompanyType':
        case 'PartnerType':
        case 'PlanonId':
        case 'RelationDescriptionList':
        case 'RelationIdList':
        case 'SearchString':
        case 'SupId':
        case 'CusId':
        case 'Uci':
          delete relations[e]
          break
        default:
          relations[e.toLowerCase()] = relations[e]
          delete relations[e]
      }
    })
    this.editVals = relations
    this.initRel = clone(val)
  }

  refresh() {
    this.$emit('refresh')
  }

  async checkIBAN(val: string) {
    if (val !== '') {
      if (hasIbanSpecialChars(val)) {
        this.editVals.bankaccountbic = ''
        this.ibanErrorMsg =
          "Iban shouldn't contains special characters or spaces."
      } else {
        try {
          const result = (await new RelationApi().checkIBAN(
            val
          )) as OpenIbanResponse
          if (result.valid) {
            this.editVals.bankaccountbic = result.bankData.bic
            this.ibanErrorMsg = ''
          } else {
            this.editVals.bankaccountbic = ''
            this.ibanErrorMsg = 'Invalid IBAN'
          }
        } catch (error) {
          this.editVals.bankaccountbic = ''
          this.ibanErrorMsg = 'Invalid IBAN'
        }
      }
    }
  }

  async updateRelation() {
    if (this.RelationForm && this.RelationForm.validate()) {
      if (this.ibanErrorMsg === '') {
        try {
          this.loading = true
          const dt = (await new RelationApi().createUpdate(
            this.editVals as RelationInterface
          )) as DefaultResponseInterface<RelationInterface>
          if (dt.result.status === 'success') {
            this.notification({
              content: 'Updated',
              color: 'success',
              position: 'right-top'
            })
            this.showModal = false
            this.$emit('refresh')
          } else {
            this.notification({
              content: 'UNEXPECTED',
              timeout: -1,
              color: 'error'
            })
          }
        } catch (err) {
          if (err.errorcode !== '') {
            this.notification({
              content: err.errorcode,
              timeout: -1,
              color: 'error'
            })
          } else {
            this.notification({
              content: 'UNEXPECTED',
              timeout: -1,
              color: 'error'
            })
          }
        }
        this.loading = false
        this.cancel()
      }
    }
  }
}
