





























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop } from 'vue-property-decorator'
import { RelationInterface } from '@/services/RelationApi'
import { clone } from '../relationTree/functions'
import { mapGetters } from 'vuex'
import EditRelation from './EditOrganisation.vue'

@Component({
  components: {
    EditRelation
  },
  filters: {
    toMaxLength(elm: string) {
      if (elm.length > 100) {
        return elm.substr(0, 100) + '...'
      }
      return elm
    }
  },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo' })
  }
})
export default class DetailsCardOrganisation extends Vue {
  @Prop(Object) readonly relation?: RelationInterface
  editMode = false
  val: RelationInterface = {}
  editVals: RelationInterface = {}

  @Watch('relation', { immediate: true })
  relationOnChanged(val: RelationInterface) {
    this.val = val
    const tmp = clone(val)
    const keys = Object.keys(tmp)
    keys.forEach((e) => {
      switch (e) {
        case 'AccountancyId':
        case 'CompanyType':
        case 'PartnerType':
        case 'PlanonId':
        case 'Reference':
        case 'RelationDescriptionList':
        case 'RelationIdList':
        case 'SearchString':
        case 'SupId':
        case 'CusId':
        case 'Uci':
          delete tmp[e]
          break
        default:
          tmp[e.toLowerCase()] = tmp[e]
          delete tmp[e]
      }
    })
    this.editVals = tmp
  }

  refresh() {
    this.$emit('refresh')
  }

  reloadRelation() {
    this.$emit('reloadRelation', this.relation)
  }

  get showEditOrganisation() {
    if (
      // @ts-ignore
      this.userDTA &&
      // @ts-ignore
      this.userDTA.data.Relation.IsCompany === 1 &&
      // @ts-ignore
      this.userDTA.data.role !== 'User'
    ) {
      return true
    }
    return false
  }

  get showEditUser() {
    if (
      // @ts-ignore
      this.userDTA &&
      // @ts-ignore
      this.userDTA.data.Relation.IsCompany === 0
    ) {
      return true
    }
    return false
  }
}
