











































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, PropSync, Ref, Watch } from 'vue-property-decorator'
import {
  hasIbanSpecialChars,
  isAddress,
  isEmail,
  isHouseNumber,
  isMaxLengthCheck
} from '../../functions/formRules'
import RelationApi from '@/services/RelationApi'
import { RelationContactInterface } from '@/services/RelationContactApi'
import RelationInterface from '@/typings/ApiTypings/RelationInterface'
import { isRequired } from '@bluecorner/shared'
import { OpenIbanResponse } from '@/typings/OpenIban'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'

@Component
export default class NewOrganisation extends Vue {
  @Prop(Object) readonly parent!: RelationContactInterface
  @Prop(Object) readonly driver!: RelationContactInterface
  @PropSync('value', { type: Boolean }) valueSynced!: boolean
  @PropSync('value', { type: Boolean }) isDriver!: boolean
  @PropSync('value', { type: Boolean }) isEdit!: boolean
  @PropSync('value', { type: Boolean }) isContactForm!: boolean
  @Ref() readonly RelationContactForm!: HTMLFormElement
  @Ref() readonly RelationForm!: HTMLFormElement

  loading = false
  valid = true
  fieldsRelationContact: RelationContactInterface = {
    relationid: '',
    firstname: '',
    lastname: '',
    telephone: '',
    email: '',
    street: '',
    housenumber: '',
    postalcode: '',
    city: '',
    remarkinternal: '',
    remarkpublic: ''
  }

  fieldsRelation: RelationInterface = {
    name: '',
    description: '',
    phone: '',
    email: '',
    vatnr: '',
    remarkInternal: '',
    remarkPublic: '',
    bankaccountiban: '',
    reference: '',
    bankaccountbic: '',
    street: '',
    housenumber: '',
    postalcode: '',
    city: ''
  }

  ibanErrorMsg = ''

  required = [isRequired()]

  rulesEmail = [isRequired(), isEmail('Invalid Email address')]

  rulesPhone = [isRequired()]

  rulesVAT = []

  rulesAddress = [isAddress('Invalid')]

  rulesHouseNumber = [isHouseNumber('Invalid house number')]

  ruleReference = [
    isMaxLengthCheck('Maximum no. of characters allowed is 50', 50)
  ]

  rulesName = [
    isMaxLengthCheck('Maximum no. of characters allowed is 100', 100),
    isRequired()
  ]

  async save() {
    this.saveRelation()
  }

  cancel() {
    this.fieldsRelation = {
      name: '',
      description: '',
      phone: '',
      email: '',
      vatnr: '',
      remarkInternal: '',
      remarkPublic: '',
      bankaccountiban: '',
      reference: '',
      bankaccountbic: '',
      street: '',
      housenumber: '',
      postalcode: '',
      city: ''
    }
    this.fieldsRelationContact = {
      relationid: '',
      firstname: '',
      lastname: '',
      telephone: '',
      email: '',
      street: '',
      housenumber: '',
      postalcode: '',
      city: '',
      remarkinternal: '',
      remarkpublic: ''
    }
    this.loading = false
    this.valueSynced = false
    this.ibanErrorMsg = ''
    this.RelationForm.resetValidation()
  }

  async checkIBAN(val: string) {
    if (val !== '') {
      if (hasIbanSpecialChars(val)) {
        this.fieldsRelation.bankaccountbic = ''
        this.ibanErrorMsg =
          "Iban shouldn't contains special characters or spaces."
      } else {
        try {
          const result = (await new RelationApi().checkIBAN(
            val
          )) as OpenIbanResponse
          if (result.valid) {
            this.fieldsRelation.bankaccountbic = result.bankData.bic
            this.ibanErrorMsg = ''
          } else {
            this.fieldsRelation.bankaccountbic = ''
            this.ibanErrorMsg = 'Invalid IBAN'
          }
        } catch (error) {
          this.fieldsRelation.bankaccountbic = ''
          this.ibanErrorMsg = 'Invalid IBAN'
        }
      }
    }
  }

  async saveRelation() {
    if (this.RelationForm && this.RelationForm.validate()) {
      if (this.ibanErrorMsg === '') {
        this.loading = true
        const tmp: RelationInterface = {
          parentid: this.parent.Id
        }
        const keys = Object.keys(this.fieldsRelation) as Array<
          keyof RelationInterface
        >
        keys.forEach((e: keyof RelationInterface) => {
          if (e) {
            // @ts-ignore
            tmp[e] = this.fieldsRelation[e]
          }
        })
        try {
          tmp.iscompany = 1
          const res = (await new RelationApi().createUpdate(
            tmp
          )) as DefaultResponseInterface<RelationContactInterface>
          if (res.result) {
            if (res.result.status === 'success') {
              this.notification({
                content: 'NEWRELATION',
                color: 'info',
                timeout: 6000
              })
              this.loading = false
              this.$emit('refresh')
              this.valueSynced = false
              this.cancel()
              return true
            }
          }
        } catch (error) {
          if (error.errorcode !== '') {
            this.notification({
              content: error.errorcode,
              timeout: -1,
              color: 'error'
            })
          } else {
            this.notification({
              content: 'UNEXPECTED',
              timeout: -1,
              color: 'error'
            })
          }
        }
        this.loading = false
        this.cancel()
      }
    }
  }

  @Watch('valueSynced')
  valueSyncedOnChanged(val: boolean, oldVal: boolean) {
    if (oldVal && !val) {
      this.fieldsRelationContact = {
        relationid: '',
        firstname: '',
        lastname: '',
        telephone: '',
        email: '',
        street: '',
        housenumber: '',
        postalcode: '',
        city: '',
        remarkinternal: '',
        remarkpublic: ''
      }
      this.fieldsRelation = {
        name: '',
        description: '',
        phone: '',
        email: '',
        vatnr: '',
        reference: '',
        remarkInternal: '',
        remarkPublic: '',
        bankaccountiban: '',
        bankaccountbic: '',
        street: '',
        housenumber: '',
        postalcode: '',
        city: ''
      }

      this.fieldsRelationContact = {
        relationid: '',
        firstname: '',
        lastname: '',
        telephone: '',
        email: '',
        street: '',
        housenumber: '',
        postalcode: '',
        city: '',
        remarkinternal: '',
        remarkpublic: ''
      }
    }
  }

  @Watch('driver')
  driverOnChanged() {
    this.fieldsRelationContact = {
      id: this.driver.id,
      relationid: this.driver.relationid,
      firstname: this.driver.firstname,
      lastname: this.driver.lastname,
      telephone: this.driver.telephone,
      email: this.driver.email,
      street: this.driver?.address?.street,
      housenumber: this.driver?.address?.housenumber,
      postalcode: this.driver?.address?.postalcode,
      city: this.driver?.address?.city,
      remarkinternal: this.driver.remarkinternal,
      remarkpublic: this.driver.remarkinternal,
      hasportal: this.driver.hasportal
    }
  }
}
