


































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, PropSync } from 'vue-property-decorator'
import RelationContactApi from '../../services/RelationContactApi'
import NewModal from '../../views/_enduser/NewUserOrganisation.vue'
import RelationSelector from '../../components/relationTree/RelationSelector.vue'
import RelationApi from '../../services/RelationApi'
import { mapGetters } from 'vuex'
import PaginationInterface from '../../typings/PaginationInterface'

@Component({
  components: {
    NewModal,
    RelationSelector
  },
  filters: {
    hasPortal(elm: any) {
      return !(elm.hasportal === 0 || !elm.isactive)
        ? this.t('$vuetify.myOrganisation.driverHasAccess')
        : this.t('$vuetify.myOrganisation.driverHasNotAccess')
    },
    toMaxLength(elm: any) {
      if (elm.length > 30) {
        return elm.substr(0, 30) + '...'
      }
      return elm
    }
  },
  computed: {
    ...mapGetters({
      userDTA: 'user/userInfo',
      contractDTA: 'chargepoints/contractDTA'
    })
  }
})
export default class TokenList extends Vue {
  @Prop(String) readonly id!: string
  @Prop(Boolean) readonly loading!: boolean
  @PropSync('search', { type: String }) searchSynced!: any

  @Prop(Array) readonly listRelationContact!: string

  isAlldrivers = false
  data: Array<any> = []
  relationInfo = {}
  payload = {
    description: '',
    relationid: '',
    printednumber: '',
    isPublicCharging: false,
    subscriptiontype: 'BCO||SUB_COSTLESS',
    firstname: '',
    lastname: '',
    relationcontactid: '',
    email: '',
    hasportal: false,
    telephone: '',
    street: '',
    housenumber: '',
    postalcode: '',
    city: '',
    country: ''
  }

  page = 1
  pageCount = 0
  pagination: PaginationInterface = {
    pagesize: 10,
    pagenumber: 1,
    type: 0
  }

  selected: any = null
  selectedType = ''
  driverTab: any = []
  driverID = ''
  showModal = false
  current: any = {}
  isEdit = false
  dialogSwitch = false
  isLoading = false
  headers = [
    {
      text: this.t('$vuetify.myOrganisation.driverTable.level'),
      value: 'description',
      width: '20%'
    },
    {
      text: this.t('$vuetify.myOrganisation.driverTable.name'),
      value: 'firstname',
      width: '20%'
    },
    {
      text: this.t('$vuetify.myOrganisation.driverTable.email'),
      value: 'email',
      width: '25%'
    },
    {
      text: this.t('$vuetify.myOrganisation.driverTable.address'),
      value: 'formattedAddress',
      width: '20%'
    },
    {
      text: this.t('$vuetify.myOrganisation.driverTable.access'),
      sortable: false,
      value: 'account'
    },
    {
      text: this.t('$vuetify.myOrganisation.driverTable.admin'),
      sortable: false,
      value: 'account'
    }
  ]

  showRelationContact(elm: any) {
    // console.log(elm)
    this.$emit('clickedRelationContact', elm)
  }

  mounted() {
    this.fetchData()
  }

  hasPortal(elm: any) {
    return !(elm.hasportal === 0 || !elm.isactive)
      ? this.t('$vuetify.myOrganisation.driverHasAccess')
      : this.t('$vuetify.myOrganisation.driverHasNotAccess')
  }

  refresh() {
    this.fetchData(this.isAlldrivers)
  }

  async switchDriver() {
    this.dialogSwitch = false
    // console.log('current driver')
    // console.log(this.current)
    this.current.relationid = this.driverID
    try {
      const result = (await new RelationContactApi().createUpdate(
        this.current
      )) as any
      this.notification({
        content: 'Driver switched',
        color: 'info',
        timeout: 6000
      })
      this.refresh()
    } catch (e) {
      this.notification({
        content: 'An error has occures, please contact support.',
        color: 'error',
        timeout: -1
      })
    }
  }

  async clicked(val: any) {
    // console.log('CALL RELATION CONTACT LIST - GET RELATION BY ID')
    this.driverID = val
    this.fetchDriverData()
    try {
      const dt = (await new RelationApi(0).getRelationById(val)) as any
      this.relationInfo = dt.data
    } catch (error) {
      console.log(error)
    }
  }

  async fetchDriverData() {
    if (this.driverID) {
      const data = (await new RelationContactApi().myRelationContacts(
        this.driverID
      )) as any
      this.driverTab = data.data
      this.resetDriver()
    }
  }

  resetDriver() {
    this.payload.firstname = ''
    this.payload.lastname = ''
    this.payload.email = ''
    this.payload.telephone = ''
    this.payload.street = ''
    this.payload.housenumber = ''
    this.payload.postalcode = ''
    this.payload.city = ''
    this.payload.country = ''
  }

  editDriver(d: any) {
    this.current = d
    this.isEdit = true
    this.showModal = true
  }

  async fetchData(isAlldrivers = false) {
    this.isLoading = true
    if (this.id) {
      const data: any = (await new RelationContactApi().myRelationContacts(
        this.id,
        !isAlldrivers
      )) as any
      this.data = data.data
      // console.log('LOADED DATA')
      // console.log(this.data)
      for (let index = 0; index < this.data.length; index++) {
        const element: any = this.data[index]
        if (element.address) {
          element.formattedAddress =
            element.address.street +
            ' ' +
            element.address.housenumber +
            ' - ' +
            element.address.postalcode +
            ' ' +
            element.address.city +
            ' ' +
            element.address.country
        }
        this.data[index] = element
      }
    }
    this.isLoading = false
  }

  async getDetails(data: any) {
    this.$router.push({
      name: 'navchargepointsdetails',
      params: { id: data.id }
    })
  }
}
