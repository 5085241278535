





















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'
import RelationContactApi, { RelationContactInterface } from '@/services/RelationContactApi'
import { clone } from '../relationTree/functions'

@Component
export default class RelationContactDetailCard extends Vue {
  @Prop(Object) readonly relationContact?: RelationContactInterface
  val: RelationContactInterface = {}
  editMode = false
  editVals: RelationContactInterface = {}
  fields: Array<any> = [
    { label: '$vuetify.relationcontact.firstName', value: 'FirstName' },
    { label: '$vuetify.relationcontact.lastName', value: 'LastName' },
    { label: '$vuetify.relationcontact.telephone', value: 'Telephone' },
    { label: '$vuetify.relationcontact.email', value: 'Email' },
    { label: '$vuetify.relationcontact.street', value: 'Street' },
    { label: '$vuetify.relationcontact.houseNumber', value: 'HouseNumber' },
    { label: '$vuetify.relationcontact.postalCode', value: 'PostalCode' },
    { label: '$vuetify.relationcontact.city', value: 'City' },
    { label: '$vuetify.relationcontact.remarkInternal', value: 'RemarkInternal' },
    { label: '$vuetify.relationcontact.remarkPublic', value: 'RemarkPublic' }
  ]

  @Watch('relationContact', { immediate: true })
  relationContactOnChanged (val: RelationContactInterface) {
    this.val = val
    const tmp = clone(val)
    const keys = Object.keys(tmp)
    keys.forEach((e) => {
      switch (e) {
        case 'CarId':
        case 'Category':
        case 'ContactType':
        case 'CostCenter':
        case 'CusUserId':
        case 'IsPerson':
        case 'Line1':
        case 'Line2':
        case 'Line3':
        case 'Line4':
        case 'Line5':
        case 'RelationDescriptionList':
        case 'RelationIdList':
        case 'SearchString':
        case 'Uci':
        case 'UserId':
          delete tmp[e]
          break
        default:
          tmp[e.toLowerCase()] = tmp[e]
          delete tmp[e]
      }
    })
    this.editVals = tmp
  }

  async updateRelationContact () {
    try {
      const dt = await new RelationContactApi().createUpdate(this.editVals) as any
      if (dt.result.status === 'success') {
        this.notification({ content: 'RelationContact Successfully updated', color: 'success', position: 'right-top' })
        this.editMode = false
        this.$emit('refresh')
      } else {
        this.notification({ content: 'An unexpected error has occured, please try again later or contact our servicedesk', timeout: -1, color: 'error' })
      }
    } catch (error) {
      this.notification({ content: 'An unexpected error has occured, please try again later or contact our servicedesk', timeout: -1, color: 'error' })
      console.error(error)
    }
  }
}
