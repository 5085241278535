import { TreeConfigModel } from './types/TreeConfigNode'

export function clone (data: any) {
  return JSON.parse(JSON.stringify(data))
}

export async function timeout (ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function setConfig (element: any, config: TreeConfigModel) {
  element.AllowedParent = config[element.Type].AllowedParent
  element.Icon = config[element.Type].Icon
  element._Display = element[config[element.Type].DisplayProperty]
  if (element.Id < 0) {
    element.Key = parseInt(`${Math.abs(element.Id)}${config[element.Type].KeySuffix}99`)
  } else {
    element.Key = parseInt(`${element.Id}${config[element.Type].KeySuffix}`)
  }
  return element
}

export function addItemConfig (config: TreeConfigModel, list: Array<any>) {
  if (list) {
    const tmp: Array<any> = []
    for (let i = 0; i < list.length; i++) {
      const element = list[i]
      if (element) {
        if (element.Children) {
          element.Children = addItemConfig(config, element.Children)
        }
        tmp.push(setConfig(element, config))
      }
    }
    return tmp
  }
  return []
}
