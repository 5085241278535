
































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import {
  BreadCrumbRelationInterface,
  RelationInterface
} from '@/services/RelationApi'
import { clone } from '../relationTree/functions'
import ChargePointList from '../chargepoint/ChargePointList.vue'
import TokenList from '../tokens/TokenList.vue'
import RelationContactList from '../relationcontact/RelationContactList.vue'
import NewModal from '../../views/_enduser/NewUserOrganisation.vue'
import AddChargepointModal from '../../components/chargepoint/AddChargepointModal.vue'
import { mapGetters } from 'vuex'
import EditRelation from './EditOrganisation.vue'

@Component({
  components: {
    NewModal,
    ChargePointList,
    RelationContactList,
    TokenList,
    AddChargepointModal,
    EditRelation
  },
  filters: {
    toMaxLength(elm: string) {
      if (elm.length > 100) {
        return elm.substr(0, 100) + '...'
      }
      return elm
    }
  },
  computed: {
    ...mapGetters({
      userDTA: 'user/userInfo',
      contractDTA: 'chargepoints/contractDTA'
    })
  }
})
export default class DetailsRC extends Vue {
  @Prop(Object) readonly relation?: RelationInterface
  @Ref() readonly RelationForm!: HTMLFormElement
  editMode = false
  isEdit = false
  isDriver = true
  loading = false
  val: BreadCrumbRelationInterface | null = null
  newRelationModal = false

  items = [
    { text: 'Real-Time', icon: 'mdi-clock' },
    { text: 'Audience', icon: 'mdi-account' },
    { text: 'Conversions', icon: 'mdi-flag' }
  ]

  tab = 0

  @Watch('relation', { immediate: true })
  relationOnChanged(val: BreadCrumbRelationInterface) {
    this.val = val
    const tmp = clone(val)
    const keys = Object.keys(tmp)
    keys.forEach((e) => {
      switch (e) {
        case 'Category':
          delete tmp[e]
          break
        default:
          tmp[e.toLowerCase()] = tmp[e]
          delete tmp[e]
      }
    })

    if (this.val && this.val.Name) {
      this.val.Breadcrumb.push({
        Description: this.val.Name.toUpperCase(),
        Last: true
      })
    }
  }

  getAddress(val: BreadCrumbRelationInterface) {
    if (val) {
      return `${val.Street} ${val.HouseNumber} - ${val.PostalCode} ${val.City} ${val.Country}`
    }
  }

  get formattedAddress() {
    if (this.val) {
      return this.getAddress(this.val)
    }
  }

  get formattedRelationAddress() {
    if (this.val && this.val.Relation) {
      return this.getAddress(this.val.Relation as BreadCrumbRelationInterface)
    }
  }

  refresh() {
    this.$emit('reloadRelation')
  }

  switchRelation(item: RelationInterface) {
    this.$emit('switchView', item)
  }
}
