var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm._.isEmpty(_vm.userDTA.data))?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"ml-5 mr-5 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listRelationContact,"search":_vm.searchSynced,"item-key":"id","loading":_vm.loading,"loading-text":_vm.t('$vuetify.myOrganisation.loadingWait'),"dense":"","hide-default-footer":"","page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"pagination":function($event){_vm.pagination = $event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.xs)?{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"mb-2",staticStyle:{"cursor":"pointer"},attrs:{"elevation":"2"},on:{"click":function($event){return _vm.showRelationContact(item)}}},[_c('v-card-text',{staticClass:"pa-5"},[_c('v-icon',{staticClass:"uil-users-alt",attrs:{"size":"18"}}),_vm._v(" "+_vm._s(_vm._f("toMaxLength")(item.relationdesc))+" "),_c('br'),_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-account ")]),_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" "),_c('br'),_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-email ")]),_vm._v(" "+_vm._s(_vm._f("toMaxLength")(item.email))+" "),_c('br'),_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-map ")]),_vm._v(" "+_vm._s(item.formattedAddress)+" "),_c('br'),_vm._v(" Access: "),_c('v-icon',{class:!(item.hasportal === 0 || !item.isactive)
                  ? 'uil uil-check-circle'
                  : 'uil uil-times-circle',attrs:{"color":!(item.hasportal === 0 || !item.isactive) ? 'success' : ''}}),_vm._v(" Admin: "),_c('v-icon',{class:item.isentitymanager
                  ? 'uil uil-check-circle'
                  : 'uil uil-times-circle',attrs:{"color":item.isentitymanager ? 'success' : ''}})],1)],1)]}}:{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.showRelationContact(item)}}},[_c('td',[_vm._v(_vm._s(_vm._f("toMaxLength")(item.relationdesc)))]),_c('td',[_vm._v(_vm._s(item.firstname)+" "+_vm._s(item.lastname))]),_c('td',[_vm._v(_vm._s(_vm._f("toMaxLength")(item.email)))]),(item.address)?_c('td',[_vm._v(" "+_vm._s(item.formattedAddress)+" ")]):_vm._e(),_c('td',[_c('v-icon',{class:!(item.hasportal === 0 || !item.isactive)
                  ? 'uil uil-check-circle'
                  : 'uil uil-times-circle',attrs:{"color":!(item.hasportal === 0 || !item.isactive) ? 'success' : ''}})],1),_c('td',[_c('v-icon',{class:item.isentitymanager
                  ? 'uil uil-check-circle'
                  : 'uil uil-times-circle',attrs:{"color":item.isentitymanager ? 'success' : ''}})],1)])]}}],null,true)})],1),(_vm.listRelationContact.length > 0)?_c('v-flex',{staticClass:"mt-5 mr-5 mb-5",attrs:{"xs12":""}},[_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"fab":"","x-small":"","color":"white","disabled":_vm.pagination.page === 1},on:{"click":function($event){_vm.page--}}},[_c('v-icon',{staticClass:"uil uil-arrow-left",attrs:{"size":"32","color":"#5D5D5D","dark":""}})],1),_c('span',{staticClass:"mx-5"},[_vm._v(_vm._s(_vm.pagination.page)+" of "+_vm._s(_vm.pagination.pageCount))]),_c('v-btn',{attrs:{"fab":"","x-small":"","color":"white","disabled":!(_vm.pagination.page < _vm.pagination.pageCount)},on:{"click":function($event){_vm.page++}}},[_c('v-icon',{staticClass:"uil uil-arrow-right",attrs:{"size":"32","color":"#5D5D5D","dark":""}})],1)],1)]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }