
























import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'
import TokenAllApi from '../../services/TokenAllApi'

@Component
export default class TokenList extends Vue {
  @Prop(String) readonly id!: string

  data: Array<any> = []

  mounted () {
    this.fetchData()
  }

  getColor (connector: any) {
    if (connector.state === 'OCCUPIED') {
      return connector.sessionstate
    }
    return connector.state
  }

  async fetchData () {
    // console.log('fetching', this.id)
    if (this.id) {
      const data = await new TokenAllApi().myTokens(this.id) as any
      this.data = data.data
      // console.log(data)
    }
  }

  async getDetails (data: any) {
    this.$router.push({ name: 'navchargepointsdetails', params: { id: data.id } })
  }

  @Watch('id', { immediate: false })
  idOnChanged (val: any, oldVal: any) {
    this.$nextTick(() => this.fetchData())
  }
}
