































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop } from 'vue-property-decorator'
import { RelationInterface } from '@/services/RelationApi'
import { clone } from '../relationTree/functions'
import ChargePointList from '../chargepoint/ChargePointList.vue'
import TokenList from '../tokens/TokenList.vue'
import RelationContactList from '../relationcontact/RelationContactList.vue'
import NewUserOrganisation from '../../views/_enduser/NewUserOrganisation.vue'
import { mapGetters } from 'vuex'
import RelationContactApi from '../../services/RelationContactApi'
import { RelationContactInterface } from '@/typings/RequestInterface'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'

interface RelationContactListInterface extends RelationContactInterface {
  formattedAddress?: string
}

@Component({
  components: {
    NewUserOrganisation,
    ChargePointList,
    RelationContactList,
    TokenList
  },
  computed: {
    ...mapGetters({
      userDTA: 'user/userInfo',
      contractDTA: 'chargepoints/contractDTA'
    })
  }
})
export default class DetailsCardDetails extends Vue {
  @Prop(Object) readonly relation?: RelationInterface
  isDriver = false
  val: RelationInterface = {}
  editVals: RelationInterface = {}
  newRelationModal = false
  loading = false
  search = ''
  currentID = '0'
  listRelationContact: Array<RelationContactInterface> = []
  isAllDrivers = false

  @Watch('relation', { immediate: true })
  relationOnChanged(val: RelationInterface) {
    this.val = val
    const tmp = clone(val)
    const keys = Object.keys(tmp)
    keys.forEach((e) => {
      switch (e) {
        case 'AccountancyId':
        case 'CompanyType':
        case 'PartnerType':
        case 'PlanonId':
        case 'Reference':
        case 'RelationDescriptionList':
        case 'RelationIdList':
        case 'SearchString':
        case 'SupId':
        case 'CusId':
        case 'Uci':
          delete tmp[e]
          break
        default:
          tmp[e.toLowerCase()] = tmp[e]
          delete tmp[e]
      }
    })
    this.editVals = tmp
    this.currentID = String(val.Id)
    this.loadRelationList()
  }

  clickedRelationContact(elm: RelationContactInterface) {
    this.$emit('clickedRelationContact', elm)
  }

  async loadRelationList(isAllDrivers = false) {
    this.loading = true
    try {
      const data = (await new RelationContactApi().myRelationContacts(
        this.currentID,
        !isAllDrivers
      )) as DefaultResponseInterface<RelationContactInterface[]>
      this.listRelationContact = data.data
      for (let index = 0; index < this.listRelationContact.length; index++) {
        const element: RelationContactListInterface =
          this.listRelationContact[index]
        if (element.address) {
          element.formattedAddress = `${element.address.street} ${element.address.housenumber} - ${element.address.postalcode} ${element.address.city} ${element.address.country}`
        }
        this.listRelationContact[index] = element
      }
      this.loading = false
    } catch (error) {
      console.log(error)
      this.loading = false
    }
  }

  @Watch('isAllDrivers', { immediate: false })
  isAllDriversOnChanged() {
    this.$nextTick(() => this.loadRelationList(this.isAllDrivers))
  }

  refresh() {
    this.loadRelationList()
  }
}
